import { Injectable, OnDestroy } from '@angular/core';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, Observable, timer, merge } from 'rxjs';
import { LoggerService } from '../loggers/logger.service';
import * as Ably from 'ably';
import { environment } from '../../../environments/environment';
import { QueueService } from '../data/queue.service';
import { DTO_QueueType } from '../api/queue-api.service';
import { PlaylistService } from '../data/playlist.service';
import { DeviceService } from '../app/device.service';
import { PrelistenAction, PlayerStatusForRemoteSharing, AppInfo, ConnectionType, PlayerAction, PlayerActionEnum, TrackAction, ExternalApplicationInfo, TrackForRemoteSharing, RemoteAudioType, TrackCommand } from '../data/vo/remote/remote-objects';
import { RemotePlayStateService } from '../audio/remote-play-state.service';
import { TrackActionService } from '../audio/track-action.service';
import { VersionedResourceType, VersionedIdResourceType } from '../data/interceptors/update-info-interceptor';
import { MusicSelectionService } from '../data/music-selection.service';
import { MusicChannelService } from '../data/music-channel.service';
import { ApplicationMode } from '@service/authentication/zone-connections.service';
import { AsyncStatus, isFinal } from '../data/vo/asyncStatus';
import { CalendarGroupService } from '../data/calendar-group.service';
import { CalendarService } from '../data/calendar.service';
import { MessageBlocksService } from '../data/message-blocks.service';
import { PlayStateService } from '@service/play-state.service';
import { TrackOrigin } from '@model/trackOrigin';

interface IUpdateToVersion
{
    type : string;
    timestamp : number;
    id: number;
    matchData: string;
}

class RemoteTrackActionStatusMap{
  trackAction: TrackAction;
  notifier: BehaviorSubject<AsyncStatus>;
}

export function retrieveExternalApplicationInfoFromMemberData(memberPresenceData: any): ExternalApplicationInfo{
  if (memberPresenceData != null){

    let appInfoObject = memberPresenceData;
    if (typeof memberPresenceData === 'string' || memberPresenceData instanceof String){
      const stringToTransform = memberPresenceData as string;
      appInfoObject = JSON.parse(stringToTransform);
    }

    const externalApplicationInfo = new ExternalApplicationInfo();
    if (Object.prototype.hasOwnProperty.call(appInfoObject, "appVersion") ) {
      externalApplicationInfo.appVersion = appInfoObject["appVersion"];
    }
    if (Object.prototype.hasOwnProperty.call(appInfoObject, "deviceInfo")){
      externalApplicationInfo.deviceInfo = appInfoObject["deviceInfo"];
    }
    if (Object.prototype.hasOwnProperty.call(appInfoObject, "connectionType") ) {
      externalApplicationInfo.connectionType = appInfoObject["connectionType"] as ConnectionType;
    }
    if (Object.prototype.hasOwnProperty.call(appInfoObject, "dedicated") ) {
      externalApplicationInfo.dedicated = appInfoObject["dedicated"] as Boolean;
    }
    if (Object.prototype.hasOwnProperty.call(appInfoObject, "connectEnabled") ) {
      externalApplicationInfo.connectEnabled = appInfoObject["connectEnabled"] as Boolean;
    }

    if (Object.prototype.hasOwnProperty.call(appInfoObject, "listensToRemoteCommands") ) {
      externalApplicationInfo.listensToRemoteCommands = appInfoObject["listensToRemoteCommands"] as Boolean;
    }else{
      externalApplicationInfo.listensToRemoteCommands = true;
    }

    return externalApplicationInfo;
  }
  return null;
}



@Injectable({
  providedIn: 'root'
})
export class DataUpdateService implements OnDestroy {

  private LOGGER_CLASSNAME = 'DataUpdateService';

  //when a remote needs to know the current player state -> emit event so that the remoteService can trigger a share if this is the current player
  private remoteNeedsToKnowPlayerStateSubject = new Subject<void>();
  public remoteNeedsToKnowPlayerState$ = this.remoteNeedsToKnowPlayerStateSubject.asObservable();

  private ablyClientSubject = new BehaviorSubject<Ably.Realtime>(null);
  public ablyClient$ = this.ablyClientSubject.asObservable();

  private get _ablyClient(): Ably.Realtime{
    return this.ablyClientSubject.value;
  }
  private set _ablyClient(client: Ably.Realtime){
    this.ablyClientSubject.next(client);
  }
  public get ablyClient(): Ably.Realtime {
    return this._ablyClient;
  }

  private amountFastFailedConnectionsCreated = 0;
  private amountConnectionsCreated = 0;

  constructor(
    private loggerService: LoggerService,
    private zoneConnectionsService: ZoneConnectionsService,
    private queueService: QueueService,
    private playlistService: PlaylistService,
    private deviceService: DeviceService,
    private remotePlayStateService: RemotePlayStateService,
    private playStateService: PlayStateService,
    private trackActionService: TrackActionService,
    private musicSelectionService: MusicSelectionService,
    private musicChannelService: MusicChannelService,
    private calendarGroupService: CalendarGroupService,
    private calendarService: CalendarService,
    private messageBlocksService: MessageBlocksService
  ) {

    if (environment.enableAbly){

      this.startAblyConnection();

      this.zoneConnectionsService.activeZoneConnection$
      .pipe(
        takeUntil(
          this.destroyed$
        )
      )
      .subscribe(
        () => {
            this.adjustConnection();
        },
        (err: unknown) => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'activeZoneConnectionSubscription', 'error from activeZoneConnectionService in DataUpdateService: ' + err);
        }
      );


      //when our applicationMode changes -> give info to other applications
    this.zoneConnectionsService.applicationMode$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.updateApplicationInfo()
      },
      (err: unknown) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'playToken.applicationMode Subscription', 'error from playTokenSubscription in DataUpdateService: ' + err);
      }
    );

    }
  }

  private fastFailTimerId: NodeJS.Timeout;
  private startAblyConnection(){

    this.allActiveChannels = [];

    if (this.restartTimerId){
      clearTimeout(this.restartTimerId);
      this.restartTimerId = null;
    }

    const options: Ably.ClientOptions = {
      key: environment.ablyKey,
      clientId: "Web player"
    };

      this._ablyClient = new Ably.Realtime(options);

      this.ablyClient.connection.on('initialized', () => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'ably.connection', 'connection initialized');
      });

      this.ablyClient.connection.on('connected', () => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'ably.connection', 'connection connected');
      });

      this.ablyClient.connection.on('connecting', () => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'ably.connection', 'connection connecting');
      });

      this.ablyClient.connection.on('disconnected', () => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'ably.connection', 'connection disconnected');
      });

      this.ablyClient.connection.on('suspended', () => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'ably.connection', 'connection suspended');
      });

      this.ablyClient.connection.on('closed', () => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'ably.connection', 'connection closed -> going to cleanup and restart');
        this.cleanupAndRestart();
      });

      this.ablyClient.connection.on('failed', () => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'ably.connection', 'connection failed');
      });

      this.adjustConnection();

      this.fastFailTimerId = setTimeout(() => {
        this.onResetFastFailAmount();
      }, this.fastFailInterval );
  }

  private onResetFastFailAmount(){
    this.loggerService.debug(this.LOGGER_CLASSNAME, 'onResetFastFailAmount', 'Going to reset the amount of fast failed connections');
    this.amountFastFailedConnectionsCreated = 0;
  }

  /**
   * When the connection is closed -> clean up all channels and the connection, try to reonnect after a period
   */

  private readonly initialDelay = 1000; // Initial delay in milliseconds
  private readonly maxDelay = 12000; // Maximum delay in milliseconds (2 min)
  private readonly fastFailInterval = 60000; // Fast fail interval in milliseconds (1 min)
  private restartTimerId: NodeJS.Timeout;

  private cleanupAndRestart(){
    if (this.fastFailTimerId){
      clearTimeout(this.fastFailTimerId);
      this.fastFailTimerId = null;
    }

    if (this.ablyClient){
      this.ablyClient.connection.off();
      this._ablyClient = null;

      this.adjustConnection();
    }


  const delay = Math.min(this.maxDelay, this.initialDelay * Math.pow(2, this.amountFastFailedConnectionsCreated));
  this.amountConnectionsCreated++;
  this.amountFastFailedConnectionsCreated++;

    this.restartTimerId = setTimeout(() => {
      this.onRestartTimer();
    }, delay );
  }

  private onRestartTimer(){
    if (this.ablyClient == null){
      this.startAblyConnection();
    }
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    if (this.currentChannel){
      this.loggerService.warn(this.LOGGER_CLASSNAME, 'ngOnDestroy', 'Going to disconnect the channel');
      this.currentChannel.presence.unsubscribe();
      this.currentChannel.unsubscribe();
    }

    if (this.ablyClient){
      this.loggerService.warn(this.LOGGER_CLASSNAME, 'ngOnDestroy', 'Going to cleanup the ably client');
      this.ablyClient.close();
      this._ablyClient = null;
    }

  }

  private connectedToChannel : string = null;
  private currentChannel : Ably.RealtimeChannel = null;

  private adjustConnection(){

    //step 1 -> check the channel to connect too
    let channelName = null;
    if (this.ablyClient){
      if (this.zoneConnectionsService.activeZoneConnection){
        channelName = 'player:' + this.zoneConnectionsService.activeZoneConnection.zoneId + ':data';
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'adjustConnection', 'No ablyClient available');
    }

    //step 2 -> disconnect if needed
    if (this.currentChannel && this.connectedToChannel && channelName != this.connectedToChannel){
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustConnection', 'Going to leave and unsubscribe from ' + this.connectedToChannel + '(connection: ' + (this.ablyClient ? this.ablyClient.connection.id : 'no connection') + ')');

      this.currentChannel.presence.leave();
      this.currentChannel.unsubscribe();

      const channelToRelease = this.currentChannel;
      this.currentChannel = null;
      this.detachChannelIfNoRefs(channelToRelease);

      this.connectedToChannel = null;
    }

    //step 3 -> connect if needed
      if (this.ablyClient && channelName && this.currentChannel == null){

          this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustConnection', 'Going to connect to ' + channelName + '(connection: ' + this.ablyClient.connection.id + ')');

          this.currentChannel = this.ablyClient.channels.get(channelName); /* inferred type Ably.Types.RealtimeChannel */
          this.connectedToChannel = channelName;
          this.currentChannel.subscribe((message) => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'channel message received', message.name + ' => ' + message.data);
            if (message.name == 'update-to-version'){
              this.handleUpdateToVersion(message.data);
            }else if (message.name == 'player-state'){
              this.handlePlayerSate(message.data);
            }else if (message.name == 'player-action'){
              this.handlePlayerAction(message.data);
            }else if (message.name == 'share-player-state'){
              this.remoteNeedsToKnowPlayerStateSubject.next();
            }else if (message.name == 'track-action'){
              this.handleTrackAction(message.data);
            }else{
              this.loggerService.warn(this.LOGGER_CLASSNAME, "currentChannel message subscribe", "Message not handled: " + message.name);
            }
          });

          /*
          this.currentChannel.presence.subscribe('enter', (member) => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'channel presence message received', 'Member ' + member.clientId + ' entered. Connection: ' + member.connectionId + '(our connection is: ' + this.ablyClient.connection.id + ')');

            this.checkConnections();
          });
          this.currentChannel.presence.subscribe('leave', (member) => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'channel presence message received', 'Member ' + member.clientId + ' leaves. Connection: ' + member.connectionId + '(our connection is: ' + this.ablyClient.connection.id + ')');
            this.checkConnections();
          });
          this.currentChannel.presence.subscribe('update', (member) => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'channel presence message received', 'Member ' + member.clientId + ' updates. Connection: ' + member.connectionId + '(our connection is: ' + this.ablyClient.connection.id + ')');
            this.checkConnections();
          });
          this.currentChannel.presence.subscribe('present', (member) => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'channel presence message received', 'Member ' + member.clientId + ' shows present. Connection: ' + member.connectionId + '(our connection is: ' + this.ablyClient.connection.id + ')');
          });

          */

          this.updateApplicationInfo();
          //this.checkConnections();
      }


  }

  //keep track of all active channels.. these channels can not be detached
  private allActiveChannels : Ably.RealtimeChannel[] = [];
  public getChannel(channelName: string): Ably.RealtimeChannel{
    let channel = this.allActiveChannels.find(channel => channel.name == channelName);
    if (channel == null && this.ablyClient != null && this.ablyClient.connection.state != 'closed'){
      channel = this.ablyClient.channels.get(channelName);
      this.allActiveChannels.push(channel);
    }
    return channel;
  }

  public releaseChannel(channel: Ably.RealtimeChannel){
    this.allActiveChannels = this.allActiveChannels.filter(c => c != channel);
    this.detachChannelIfNoRefs(channel);
  }

  private detachChannelIfNoRefs(channel: Ably.RealtimeChannel){
    if (channel != this.currentChannel && this.allActiveChannels.filter(c => c == channel).length == 0){
      //we can only detach if it is not used by the zone-precence service!!
      channel.detach();
      this.loggerService.debug(this.LOGGER_CLASSNAME, "releaseChannelIfNoRefs", "detached from " + channel.name);
    }
  }



  private handleUpdateToVersion(message: string){
    this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleUpdateToVersion', 'Going to handle: ' + message);
    const updateToVersionMessage : IUpdateToVersion = JSON.parse(message);
    switch (updateToVersionMessage.type) {
      case VersionedResourceType.queue_radio:
        this.queueService.handleUpdateInfo(DTO_QueueType.Radio, updateToVersionMessage.timestamp);
        break;
      case VersionedResourceType.queue_nextRadio:
        this.queueService.handleUpdateInfo(DTO_QueueType.NextRadio, updateToVersionMessage.timestamp);
        break;
      case VersionedResourceType.queue_waiting:
        this.queueService.handleUpdateInfo(DTO_QueueType.Waiting, updateToVersionMessage.timestamp);
        break;
      case VersionedResourceType.custom_playlists:
        this.playlistService.handleCustomPlaylistsUpdateInfo(updateToVersionMessage.timestamp);
      break;
      case VersionedResourceType.music_selection:
        this.musicSelectionService.handleMusicSelectionUpdateInfo(updateToVersionMessage.timestamp);
      break;
      case VersionedResourceType.calendarGroup:
        this.calendarGroupService.handleCalendarGroupsUpdateInfo(updateToVersionMessage.timestamp);
      break;
      case VersionedResourceType.musicChannelGroupRadio:
        this.musicChannelService.handleMusicChannelGroupsUpdateInfo(updateToVersionMessage.timestamp);
      break;
      case VersionedResourceType.messagesPlanning:
        this.messageBlocksService.handleMessagesPlanningUpdateInfo(updateToVersionMessage.timestamp);
      break;
      case VersionedIdResourceType.playlist:
        this.playlistService.handlePlaylistUpdateInfo(updateToVersionMessage.id, updateToVersionMessage.timestamp);
      break;
      case VersionedIdResourceType.musicCollection:
        this.musicChannelService.handleMusicCollectionUpdateInfo(updateToVersionMessage.id, updateToVersionMessage.matchData, updateToVersionMessage.timestamp);
      break;
      case VersionedIdResourceType.calendar:
        this.calendarGroupService.handleCalendarUpdateInfo(updateToVersionMessage.id, updateToVersionMessage.timestamp);
        this.calendarService.handleCalendarUpdateInfo(updateToVersionMessage.id, updateToVersionMessage.timestamp);
      break;
      default:
        this.loggerService.error(this.LOGGER_CLASSNAME, 'handleUpdateToVersion', 'type not implemented: ' + updateToVersionMessage.type);
    }
  }

  private handlePlayerSate(message: string){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.remoteMode){
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'handlePlayerSate', 'Going to handle: ' + message);
      const playerStatus : PlayerStatusForRemoteSharing = JSON.parse(message);
      if (playerStatus.current.track){
        this.remotePlayStateService.remoteTrackReceived(playerStatus.current.track);
        this.checkRemoteTrackCompletesAnything(playerStatus.current.track);
      }
      if (playerStatus.current.progress){
        this.remotePlayStateService.remoteProgressReceived(playerStatus.current.progress);
      }
      if (playerStatus.current.playMode){
        this.remotePlayStateService.remotePlayModeReceived(playerStatus.current.playMode);
      }
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'handlePlayerSate', 'Not in remote mode, going to ignore: ' + message);
    }

  }

  private handlePlayerAction(message: string){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'handlePlayerAction', 'Going to handle: ' + message);
      const playerAction : PlayerAction = JSON.parse(message);
      if (playerAction.action == PlayerActionEnum.play){
        this.playStateService.play();
      }else if (playerAction.action == PlayerActionEnum.pause){
        this.playStateService.pause();
      }else if (playerAction.action == PlayerActionEnum.skip){
        this.playStateService.startNextAudioFile();
      }
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'handlePlayerAction', 'Not in player mode, going to ignore: ' + message);
    }
  }

  private handleTrackAction(message: string){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleTrackAction', 'Going to handle: ' + message);
      const trackAction : TrackAction = JSON.parse(message);
      this.trackActionService.performTrackCommand(trackAction.trackId, trackAction.trackOrigin, trackAction.command);
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleTrackAction', 'Not in player mode, going to ignore: ' + message);
    }
  }


  public bootService(){

  }

  /**
   * When turning into a player -> shortly ignore other active players to give them a period to disconnect as a player
   */
  //we are delaying are messages to avoid to much trafic

  /*
  private ignoreOtherPlayersTimeOut: NodeJS.Timeout;
  private ignoreOtherPlayers = false;
  private ignoreOtherPlayersForShortPeriod(){
   this.clearDelayedCheckConnections();
   this.ignoreOtherPlayers = true;
    this.ignoreOtherPlayersTimeOut = setTimeout(() => {
      this.ignoreOtherPlayers = false;
      this.checkConnections();
    }, 5000);
  }

  private clearDelayedCheckConnections(){
    if (this.ignoreOtherPlayersTimeOut){
      clearTimeout(this.ignoreOtherPlayersTimeOut);
      this.ignoreOtherPlayersTimeOut = null;
    }
  }
  */

  /**
   * PLAYER MODE CONNECTION: Shares this applications info
   */




  private appInfo: AppInfo = new AppInfo();
  private updateApplicationInfo(){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.appInfo.connectionType = ConnectionType.player;
    }else if (this.zoneConnectionsService.applicationMode == ApplicationMode.remoteMode){
      this.appInfo.connectionType = ConnectionType.remote;
    } else{
      this.appInfo.connectionType = ConnectionType.none;
    }

    this.appInfo.deviceInfo = this.deviceService.deviceShortReadableInfo();

    if (this.currentChannel != null){
      const appInfoJsonString = JSON.stringify(this.appInfo);
      this.currentChannel.presence.update(appInfoJsonString);
    }
  }
/*
  private retrieveApplicationInfoFromMemberData(memberPresenceData: any): AppInfo{
    if (memberPresenceData != null){

      let appInfoObject = memberPresenceData;
      if (typeof memberPresenceData === 'string' || memberPresenceData instanceof String){
        const stringToTransform = memberPresenceData as string;
        appInfoObject = JSON.parse(stringToTransform);
      }

      const appInfo = new AppInfo();
      if (Object.prototype.hasOwnProperty.call(appInfoObject, "connectionType") ) {
        appInfo.connectionType = appInfoObject["connectionType"] as ConnectionType;
      }
      if (Object.prototype.hasOwnProperty.call(appInfoObject, "deviceInfo")){
        appInfo.deviceInfo = appInfoObject["deviceInfo"];
      }
      if (Object.prototype.hasOwnProperty.call(appInfoObject, "appVersion")){
        appInfo.appVersion = appInfoObject["appVersion"];
      }
      return appInfo;
    }
    return null;
  }
  */

  /**
   * REMOTE APPS: what other apps are connected
   */

/*
  //other remote connected
  private _otherRemoteConnectedSubject = new BehaviorSubject<boolean>(false);
  public otherRemoteConnected$ = this._otherRemoteConnectedSubject.asObservable();

  private get _otherRemoteConnected(): boolean {
    return this._otherRemoteConnectedSubject.value;
  }
  private set _otherRemoteConnected(value: boolean) {
    if (this._otherRemoteConnected !== value) {
      this._otherRemoteConnectedSubject.next(value);
    }
  }

  public get otherRemoteConnected(): boolean {
    return this._otherRemoteConnected;
  }

  //other player connected
  private _otherPlayerConnectedSubject = new BehaviorSubject<boolean>(false);
  public otherPlayerConnected$ = this._otherPlayerConnectedSubject.asObservable();

  private get _otherPlayerConnected(): boolean {
    return this._otherPlayerConnectedSubject.value;
  }
  private set _otherPlayerConnected(value: boolean) {
    if (this._otherPlayerConnected !== value) {
      this._otherPlayerConnectedSubject.next(value);
    }
  }

  public get otherPlayerConnected(): boolean {
    return this._otherPlayerConnected;
  }

  //other player name (null if none connected)
  private _otherPlayerNameSubject = new BehaviorSubject<string>(null);
  public otherPlayerName$ = this._otherPlayerNameSubject.asObservable();

  private get _otherPlayerName(): string {
    return this._otherPlayerNameSubject.value;
  }
  private set _otherPlayerName(value: string) {
    if (this._otherPlayerName !== value) {
      this._otherPlayerNameSubject.next(value);
    }
  }

  public get otherPlayerName(): string {
    return this._otherPlayerName;
  }

*/
/*
  private checkConnections(){

    let tempOtherRemoteConnected = false;
    let tempOtherPlayerConnected = false;
    let tempOtherPlayerName : string = null;
    if (this.currentChannel){
      this.currentChannel.presence.get((err, members) => {
        if (err == null && members != null){

          this.loggerService.debug(this.LOGGER_CLASSNAME, "checkConnections", members.length + " client connections found");

          members.forEach(member => {
            if (member.connectionId == this.ablyClient.connection.id){
              this.loggerService.debug(this.LOGGER_CLASSNAME, "checkConnections", "going to skip our own connection: " + member.clientId + '(connection: ' + member.connectionId + ')');
            }else{
              this.loggerService.debug(this.LOGGER_CLASSNAME, "checkConnections", "checking client connection: " + member.clientId + '(connection: ' + member.connectionId  + ')');
              const memberAppInfo = this.retrieveApplicationInfoFromMemberData(member.data);
              if (memberAppInfo != null && memberAppInfo.connectionType != null){
                if (memberAppInfo.connectionType == ConnectionType.player){
                  tempOtherPlayerConnected = true;
                  this.loggerService.debug(this.LOGGER_CLASSNAME, "checkConnections", member.clientId + " is a player" + '(connection: ' + member.connectionId  + ')');
                  tempOtherPlayerName = memberAppInfo.deviceInfo;
                }else if (memberAppInfo.connectionType == ConnectionType.remote){
                  tempOtherRemoteConnected = true;
                  this.loggerService.debug(this.LOGGER_CLASSNAME, "checkConnections", member.clientId + " is a remote" + '(connection: ' + member.connectionId  + ')');
                }
              }
            }

          });
        }
        this._otherRemoteConnected = tempOtherRemoteConnected;
        this._otherPlayerConnected = tempOtherPlayerConnected;
        this._otherPlayerName = tempOtherPlayerName;

        this.playTokenService.remotePlayerInfoReceived(this.otherPlayerConnected, this.otherPlayerName);

      });
    }

    //also sync when no connection:
    this._otherRemoteConnected = tempOtherRemoteConnected;
    this._otherPlayerConnected = tempOtherPlayerConnected;
    this._otherPlayerName = tempOtherPlayerName;

  }
  */

  /**
   * SEND MESSAGES
   */
  public sendMessage(message: string, data: Object){
    if (this.currentChannel != null){
      const stringMessage = data != null ? JSON.stringify(data) : null;
      this.currentChannel.publish(message, stringMessage)
      .then(() => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, "sendMessage", "send " + message + " message done");
      })
      .catch((err) => {
        this.loggerService.error(this.LOGGER_CLASSNAME, "sendMessage", "Unable to publish message; err = " + err.message);
      });
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "sendMessage", "should send message " + message + ", but not connection is available");
    }
  }

  public sendPlayerStatus(playerStatusForRemoteSharing: PlayerStatusForRemoteSharing){
    this.sendMessage('player-state', playerStatusForRemoteSharing);
  }

  public sendPrelistenAction(prelistenAction: PrelistenAction){
    this.sendMessage('prelisten-command', prelistenAction);
  }

  public sendPlayerAction(playerAction: PlayerAction){
    this.sendMessage('player-action', playerAction);
  }

  //we keep a reference to all remote trackActions and check if one completes once we receive feedback
  private remoteTrackActionStatus: RemoteTrackActionStatusMap[] = [];

  public sendTrackAction(trackAction: TrackAction): Observable<AsyncStatus>{

    const remoteTrackActionStatusMap: RemoteTrackActionStatusMap = new RemoteTrackActionStatusMap();
    remoteTrackActionStatusMap.trackAction = trackAction;
    remoteTrackActionStatusMap.notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);
    this.remoteTrackActionStatus.push(remoteTrackActionStatusMap);

    //start a timer to finish this action with a failed status
    timer(10000)
      .pipe(
        takeUntil(
          merge(
            remoteTrackActionStatusMap.notifier.pipe(filter(status => isFinal(status))),
            this.destroyed$
          )
        )
      )
      .subscribe(
        ()=>{
          const index = this.remoteTrackActionStatus.indexOf(remoteTrackActionStatusMap);
          if (index >= 0){
            remoteTrackActionStatusMap.notifier.next(AsyncStatus.ERROR)
            remoteTrackActionStatusMap.notifier.complete();
            this.remoteTrackActionStatus.splice(index, 1);
          }
        }
      );

    this.sendMessage('track-action', trackAction);
    return remoteTrackActionStatusMap.notifier;
  }

  private checkRemoteTrackCompletesAnything(trackForRemoteSharing: TrackForRemoteSharing){

    if (trackForRemoteSharing.type == RemoteAudioType.song || trackForRemoteSharing.type == RemoteAudioType.message){
      let i = this.remoteTrackActionStatus.length;
      while (i--) {
        if (this.remoteTrackActionStatus[i].trackAction.trackId == trackForRemoteSharing.id) {
          this.remoteTrackActionStatus[i].notifier.next(AsyncStatus.COMPLETED);
          this.remoteTrackActionStatus[i].notifier.complete();
          this.remoteTrackActionStatus.splice(i, 1);
        }
      }
    }
  }

  public sendSharePlayerState(){
    this.sendMessage('share-player-state', null);
  }

}
