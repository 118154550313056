import { Component, OnInit, EventEmitter, Output, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { AppService } from '../../../../services/app/app.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { Observable, Subject, BehaviorSubject, merge, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ExternalLinksService } from '../../../../services/app/external-links.service';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { SplitInputService } from '@components/split-input/split-input.service';


export enum ZoneCodeConnectionsState {
  connecting,
  creatingConnection,
  createError,
  clean
}

@Component({
  selector: 'tun-connect-zone-view',
  templateUrl: './connect-zone-view.component.html',
  styleUrls: ['./connect-zone-view.component.scss']
})
export class ConnectZoneViewComponent implements OnInit, AfterViewInit {

  public codeInputEnabled = true;

  faAngleLeft = faAngleLeft

  //@ViewChild('ngOtpInput') ngOtpInput: any;

  @ViewChild('firstInput', { static: false })
   set input(element: ElementRef<HTMLInputElement>) {
     if(element) {
       element.nativeElement.focus()
     }
  }

  @Output() close = new EventEmitter<void>();

  ZoneCodeConnectionsState = ZoneCodeConnectionsState;

  get connectEnabled$(){
    return combineLatest([this.zoneConnectionsService.creatingZoneConnection$, this.otp$])
     .pipe( map(
      ([creatingZoneConnection, otp]) => {
        return !creatingZoneConnection && otp != null && otp.length >=6
      })
     );
  }

  get backEnabled$(){
    return this.zoneConnectionsService.zoneConnections$
    .pipe(
      map(connections => connections != null && connections.length > 0)
    )
  }

  get creatingConnection$(){
    return this.zoneConnectionsService.creatingZoneConnection$;
  }

  get creationError$(){
    return this.zoneConnectionsService.creatingZoneConnectionError$;
  }

  get heightPerItem$(){
    return this.appService.heightPerItem$;
  }

  /*
  get itemWidth$(): Observable<number>{
    return this.appService.heightPerItem$
      .pipe(
        map(height => {
          return 6 * height
        })
      );
  }
  */

  get itemWidth$(): Observable<number>{
    return this.appService.widthForMenuPanel$
            .pipe(
              map(width => Math.max(width, 200))
            );
  }


  get zoneCodeConnectionsState(){
    if (this.zoneConnectionsService.creatingZoneConnection){
      return ZoneCodeConnectionsState.creatingConnection;
    }else if (this.zoneConnectionsService.activeZoneConnection != null){
      return ZoneCodeConnectionsState.connecting;
    }else if (this.zoneConnectionsService.creatingZoneConnectionError != null){
      return ZoneCodeConnectionsState.createError;
    }
    return ZoneCodeConnectionsState.clean;
  }



  constructor(
    private appService: AppService,
    private zoneConnectionsService: ZoneConnectionsService,
    private externalLinksService: ExternalLinksService,
    private splitInputService: SplitInputService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.zoneConnectionsService.creatingZoneConnection$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      creatingConnection => {
        if (creatingConnection == false){
          //clean input

          //this.ngOtpInput.otpForm.enable();
          //this.ngOtpInput.setValue("");

          this.codeInputEnabled = true;
          this.splitInputService.clearSplitInput();
          this.otp$.next(null);
        }else{
          this.codeInputEnabled = false;

          //this.ngOtpInput.otpForm.disable();
        }
      }

    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
  }

  public onClose(){
    this.close.emit();
  }

  public onEnter(){
    this.connect();
  }


  public connect(){
    if (!this.zoneConnectionsService.creatingZoneConnection && this.otp$.value && this.otp$.value.length >= 6){
      this.zoneConnectionsService.connectZoneForZoneCode(this.otp$.value, false, null);
    }
  }

  private otp$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  /*
  public onOtpChange(otp){
    this.otp = otp;
  }
  */

  public handleZoneCodeCompleted(value: string): void {
    //console.log(value);
    this.otp$.next(value);
  }

  public onHelpClick(){
    this.externalLinksService.openConnectZoneHelp();
  }

  public onRegisterClick(){
    this.externalLinksService.openRegisterPage();
  }

  public clearWhenEmpty(value: string){
    if (value == null || value.length == 0){
      this.otp$.next(null);
    }
  }

}
