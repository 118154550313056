import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CalendarItem } from '@model/calendarItem';
import { MusicChannelService } from '@service/music-channel.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CALENDAR_ITEM_BASE_HEIGTH_REM } from '../../calendar-detail-view.component';
import { DragDropService } from '@service/drag-drop.service';
import { CalendarService } from '@service/calendar.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { PlayingItemService } from '@service/app-v5/playing-item.service';

@Component({
  selector: 'tun-calendar-item-view',
  templateUrl: './calendar-item-view.component.html',
  styleUrls: ['./calendar-item-view.component.scss']
})
export class CalendarItemViewComponent implements AfterViewInit, OnDestroy, OnInit {

  public LOGGER_NAME = CalendarItemViewComponent.name;

  public get performingActionOnItem(){
    return this.calendarItem && (this.calendarItem.removing || this.calendarItem.creating || this.calendarItem.saving);
  }

  private _editMode = false
  @Input() set editMode(value: boolean) {
    this._editMode = value;
    if (!value){
      this.changingTime = false;
    }

  }
  get editMode(): boolean {
    return this._editMode;
  }

  get isMouseDown$(){
    return this._dragDropService.isMouseDown$;
  }

  public playAnimationColors : MusicPlayAnimationColors


  // === State === //
  private _musicChannelName: string;

  public baseREM = CALENDAR_ITEM_BASE_HEIGTH_REM;


  private _calendarItem: CalendarItem;
  @Input() set calendarItem(value: CalendarItem) {
    this._calendarItem = value;

    if (value.creating){
      this.loggerService.debug(this.LOGGER_NAME, "calendarItem setter", "detected a CREATING calendarItem");
    }

    let musicChannel = null;
    if (value && value.musicChannelId != 0) {
      musicChannel = this._musicChannelService.findMusicChannelById(
        this._calendarItem.musicChannelId
      );
    }
    if (musicChannel) {
      this._musicChannelName = musicChannel.name;
    } else {
      this._musicChannelName = '';
    }
  }
  get calendarItem(): CalendarItem {
    return this._calendarItem;
  }

  @Input() isSelected: boolean;
  @Input() isPlaying: boolean;
  @Input() inEditMode: boolean;

  @Output() openTweakPanel = new EventEmitter<CalendarItem>();
  @Output() deleteItem = new EventEmitter<CalendarItem>();

  @Output() selectMusicChannelForCalendarItem = new EventEmitter<CalendarItem>();
  @Output() selectPlaylistForCalendarItem = new EventEmitter<CalendarItem>();

  selected: boolean;


  public get showPlayAnimation$(){
    return this.playingMusicSelectionService.playingMusicSelection$
      .pipe(
        map(playingMusicSelection => {
          return playingMusicSelection.origin == PlayingOrigin.calendar && playingMusicSelection.calendarItem == this.calendarItem
        })
      )
  }

  public get animatePlayAnimation$(){
    return this.playingItemService.isPlaying$
  }


  constructor(
    private _musicChannelService: MusicChannelService,
    private _cdRef: ChangeDetectorRef,
    private _dragDropService: DragDropService,
    private loggerService: LoggerService,
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private playingItemService: PlayingItemService
  ) {}

  ngOnInit() {

    this.playAnimationColors = new MusicPlayAnimationColors()
    .selectedColor('#FFFFFF')
    .unselectedColor('#FFFFFF');

    this.calendarItem.timingForItemChanged$
      .pipe(takeUntil(this._destroy$))
      .subscribe(_ => {

        this._cdRef.detectChanges();
      });

    this.calendarItem.performingActionChanged$
    .pipe(takeUntil(this._destroy$))
    .subscribe(_ => {
      this._cdRef.detectChanges();
    });
  }

  ngAfterViewInit() {

  }

  private _destroy$ = new Subject<void>();
  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }

  onOpenEditPopup(){

  }

  onRemove(){
    this.deleteItem.emit(this.calendarItem);
  }

  public changingTime = false;
  onChangeTime(){
    this.changingTime = true;
  }
  onCloseChangeTime(){
    this.changingTime = false;
  }

  onTweak(){
    this.openTweakPanel.emit(this.calendarItem);
  }

  onChangeMusicChannel(){
    this.selectMusicChannelForCalendarItem.emit(this.calendarItem);
  }

  onChangePlaylist(){
    this.selectPlaylistForCalendarItem.emit(this.calendarItem);
  }



  onExtenderMouseDown = (event: MouseEvent, isTop: boolean) => {
    const {
      y,
      top
    } = (event.target as HTMLElement).getBoundingClientRect() as DOMRect;

    this._dragDropService.isMouseDown = true;
    this._dragDropService.resizingCalendarItem = this.calendarItem;
    this._dragDropService.resizingCalendarItemYcoordinate = y || top;
    this._dragDropService.resizingCalendarItemDuration = this.calendarItem.duration;
    this._dragDropService.resizingCalendarItemStartTime =
      this.calendarItem.startHour +
      (this.calendarItem.startMinutes === 0 ? 0 : 0.5);
    this._dragDropService.resizeFromTop = isTop;

  }

}
