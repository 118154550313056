import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@service/authentication.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggerService } from '@service/loggers/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { ZoneConnectionService } from '../authentication/zone-connection.service';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';


@Injectable({
  providedIn: 'root'
})
export class ExternalLinksService implements OnDestroy{

  private LOGGER_CLASSNAME = 'ExternalLinksService';

  private HELP_CENTER_BASE_LOCATION = "https://help.tunify.com/"

  private BUY_LOCATION = "/dashboard/direct/zones/{zoneId}?type=purchase";
	private UPGRADE_LOCATION = "/dashboard/direct/zones/{zoneId}?type=change";
  private BUY_LOCATION_TO_PLAYBACK_LIST = "/dashboard/subscriptions/overview";

  private lastLoggedInZoneId: number;
  private lastLoggedInExternalZoneId: string;



  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private zoneConfigurationService: ZoneConfigurationService,
    private loggerService: LoggerService,
    private translateService:TranslateService
  ) {

    this.zoneConnectionsService.activeZoneConnection$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (zoneConnection) => {
          if (zoneConnection){
            this.lastLoggedInZoneId = zoneConnection.zoneId;
            this.lastLoggedInExternalZoneId = zoneConnection.externalZoneId
          }
        }
      );



  }

  private destroyed$ = new Subject();
  ngOnDestroy(){
    this.destroyed$.next(null);
    this.destroyed$ = null;
  }

  public openDownloadPage(){
    this.open('download/index.html');
  }

  public openHelpGreen(){
    this.open(environment.helpGreen);
  }

  public openHelpBlue(){
    this.open(environment.helpBlue);
  }

  public openHelpOrange(){
    this.open(environment.helpOrange);
  }

  public openConnectZoneHelp(){
    const link = this.translateService.instant("link.playerCode.href");
    if (link != null){
      this.open(link);
    }
    //this.open(environment.helpConnect);
  }

  public openRegisterPage(){
    this.open(environment.mytunifyRegister);
  }


  public openHelpCenter(){
    this.open(this.HELP_CENTER_BASE_LOCATION + this.zoneConfigurationService.language);
  }

  public openZoneBuyPage(){
    if (this.lastLoggedInExternalZoneId != null && this.lastLoggedInExternalZoneId != ""){
      const url = environment.mytunifyBaseUrl + this.BUY_LOCATION.replace("{zoneId}", this.lastLoggedInExternalZoneId )
      this.open(url);
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "openUpgradePage", "no last logged in zone known -> going to open general buy page");
      this.openGeneralBuyPage();
    }
  }

  public openZoneUpgradePage(){
    if (this.lastLoggedInExternalZoneId != null && this.lastLoggedInExternalZoneId != ""){
      const url = environment.mytunifyBaseUrl + this.UPGRADE_LOCATION.replace("{zoneId}", this.lastLoggedInExternalZoneId )
      this.open(url);
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "openUpgradePage", "no last logged in zone known -> going to open general buy page");
      this.openGeneralBuyPage();
    }
  }

  public openGeneralBuyPage(){
    this.open(environment.mytunifyBaseUrl + this.BUY_LOCATION_TO_PLAYBACK_LIST);
  }

  public openPreviousVersion(){
    this.openSameWindow(environment.previousVersionUrl);
  }

  public openFirefoxAutoPlayHelp(){
    let targetUrl = "https://support.mozilla.org/en-US/kb/block-autoplay";
    if (this.translateService.getBrowserLang() == "nl"){
      targetUrl = "https://support.mozilla.org/nl/kb/automatisch-afspelen-blokkeren";
    }else if (this.translateService.getBrowserLang() == "fr"){
      targetUrl = "https://support.mozilla.org/fr/kb/autoriser-bloquer-lecture-automatique-medias";
    }else if (this.translateService.getBrowserLang() == "de"){
      targetUrl = "https://support.mozilla.org/de/kb/automatische-tonwiedergabe-erlauben-bzw-blockieren";
    }
    this.open(targetUrl);
  }

  public openCoronaPlayer(){
    this.open("https://corona.tunify.com");
  }


  private open(url: string){
    window.open(url, "_blank");
  }

  private openSameWindow(url: string){
    window.open(url, "_self");
  }

}
