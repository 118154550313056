import { Component, Input, OnDestroy } from '@angular/core';
import { CALENDAR_ITEM_BASE_HEIGTH_REM } from '../../calendar-detail-view.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'tun-timeline-view',
  templateUrl: './timeline-view.component.html',
  styleUrls: ['./timeline-view.component.scss']
})
export class TimelineViewComponent implements OnDestroy{
  // === Props === //
  @Input() public currentTimeMultiplier: number;
  @Input() public hours: string[];

  // === State === //
  public baseHeightREM = CALENDAR_ITEM_BASE_HEIGTH_REM;

  // === Observables === //
  private _destroy$ = new Subject<void>();


  constructor() {

  }

  ngOnInit() {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }
}
