import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { BehaviorSubject, merge, Subject, timer, Observable, combineLatest } from 'rxjs';
import { filter, finalize, map, takeUntil, switchMap } from 'rxjs/operators';
import { ZoneConnection } from '../../model/zoneConnection';
import { AuthenticationApiService } from '../api/authentication-api.service';
import { ZoneConnectionApiService, DTO_RefreshToken } from '../api/zone-connection-api.service';
import { LocalStorageService } from '../app/local-storage.service';
import { LoggerService } from '../loggers/logger.service';
import { ZoneConnectionService } from './zone-connection.service';
import { ZoneConnectionReviver } from './zoneConnectionReviver';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ConnectionType } from '@service/vo/remote/remote-objects';
import { ExternalApplicationInfo } from '../data/vo/remote/remote-objects';

export enum ApplicationMode {
  playerMode = "playerMode",
  remoteMode = "remoteMode"
}

@Injectable({
  providedIn: 'root'
})
export class ZoneConnectionsService implements OnDestroy {

  private LOGGER_CLASSNAME = 'ZoneConnectionsService';

  //when a player is configured for auto start, we first detect other connected applications or until this threshold is reached
  private secondsBeforeAutoStart = 10;

  //events when a zoneConnection is added (by using a zoneCode)
  private zoneConnectionAddedSource = new Subject<ZoneConnection>();
  zoneConnectionAdded$ = this.zoneConnectionAddedSource.asObservable();
  private emitZoneConnectionAdded(zoneConnection: ZoneConnection) {
    this.zoneConnectionAddedSource.next(zoneConnection);
  }

  constructor(
    private localStorageService: LocalStorageService,
    private authenticationApiService: AuthenticationApiService,
    private loggerService: LoggerService,
    private zoneConnectionApiService: ZoneConnectionApiService,
    private zoneConnectionService: ZoneConnectionService,
    private ngZone: NgZone
  ) {

    this.currentPlayerApplicationInfos$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      currentPlayerApplicationInfos => {
        if (currentPlayerApplicationInfos != null && currentPlayerApplicationInfos.length > 0){
          this.lastPlayerApplicationInfoSubject.next(currentPlayerApplicationInfos[0]);
        }
      }
    )

    //as a test, we are going to read the last used version from the local storage (to test if local storage is working)
    if (this.localStorageService.lastUsedVersion != undefined){
      if (this.localStorageService.lastUsedVersion != environment.VERSION){
        this.loggerService.debug(this.LOGGER_CLASSNAME, "constructor", "last used version: " + this.localStorageService.lastUsedVersion + ". New version detected: " + environment.VERSION);
      }else{
        this.loggerService.debug(this.LOGGER_CLASSNAME, "constructor", "last used version: " + this.localStorageService.lastUsedVersion + ". We are up to date.");
      }
    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, "constructor", "First time use or local storage not working");
    }

    this.localStorageService.lastUsedVersion = environment.VERSION;

    if (this.localStorageService.lastUsedVersion == undefined){
      this.loggerService.error(this.LOGGER_CLASSNAME, "constructor", "Can not use localStorage!");
    }

    this.loadZoneConnections();



    this.zoneConnectionService.zoneDataChanged$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.saveZoneConnections();
      }
    )

    this.zoneConnectionService.zoneConnectionBecomesInvalid$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (zoneConnection) => {
        //check if it is invalid
        if (!zoneConnection.valid){
          this.loggerService.warn(this.LOGGER_CLASSNAME, "zoneConnectionBecomesInvalid", "zoneConnection invalid received. Going to remove connection for zone " + zoneConnection.zoneId);
          if (this._activeZoneConnection == zoneConnection){
            this.activateZoneConnection(null, null);
          }
          this._zoneConnections = this.zoneConnections.filter(zoneConnectionInArray => {return zoneConnectionInArray != zoneConnection});
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, "zoneConnectionBecomesInvalid", "zoneConnection invalid received for a valid connection. zone " + zoneConnection.zoneId);
        }

      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
  }

  /**
   * All known zoneConnections
   */
  private _zoneConnectionsSubject = new BehaviorSubject<readonly ZoneConnection[]>([]);
  zoneConnections$ = this._zoneConnectionsSubject.asObservable();
  private set _zoneConnections(value: readonly ZoneConnection[]){
    if (value == null){
      value = [];
    }

    if (environment.testMultipleZoneConnections &&  value.length > 0 && value.length < 5){
      const test : ZoneConnection[] = [];
      for(let i = 0; i < 5; i++){
        test.push(value[0]);
      }
      this._zoneConnectionsSubject.next(test);
    }else{
      this._zoneConnectionsSubject.next(value);
      this.saveZoneConnections();
    }




  }
  private get _zoneConnections():readonly ZoneConnection[]{
    return this._zoneConnectionsSubject.value;
  }
  public get zoneConnections():readonly ZoneConnection[]{
    return this._zoneConnections;
  }

  /**
   * The current active zoneConnection
   *
   * The zoneConnection is only set to active to the outside world once the applicationMode is known (player - remote)
   */
  private _activeZoneConnectionSubject = new BehaviorSubject<ZoneConnection>(null);
  private _activeZoneConnection$ = this._activeZoneConnectionSubject.asObservable();
  private activeZoneConnectionChanged$ = new Subject<void>(); //we need a normal subject for the takeUntil
  private set _activeZoneConnection(value: ZoneConnection){
    if (this._activeZoneConnection != null){
      //logged out -> remove access token etc
      this._activeZoneConnection.accessToken = null;
      if (this.applicationMode == ApplicationMode.playerMode){
        this._lastActivePlayerZoneConnection = this._activeZoneConnection;
      }else{
        this._lastActivePlayerZoneConnection = null;
      }
    }

    if (value != null){
      //when a zoneConnection is used -> always remove the connection (if already known) and add it as first to the list
      this.addZoneConnectionToFront(value);
    }


    this._activeZoneConnectionSubject.next(value);
    this.activeZoneConnectionChanged$.next();

    //listen for events
    if (this._activeZoneConnection != null){

      //when loading an accessToken failed for the active zoneConnection
      this._activeZoneConnection.loadAccessTokenDone$
      .pipe(
        filter(
          success => success == false
        ),
        takeUntil(
          merge(
            this.destroyed$,
            this.activeZoneConnectionChanged$,
          )
        )
      )
      .subscribe(
        () => {

          if (this._activeZoneConnection.accessTokenRefreshFails > 2){
            //if it failed to much -> start logging
            this.loggerService.error(this.LOGGER_CLASSNAME, "loadAccessTokenDone", "AccessToken could not be fetched for zoneId " + this._activeZoneConnection.zoneId + " with token " + this._activeZoneConnection.refreshToken);
          }
          if (this._activeZoneConnection.accessTokenRefreshFails > 10){
            this._activeZoneConnection.errorMessage = "zoneConnections.invalid.info";
            this._activeZoneConnection = null;
          }
          else{
            //retry in x seconds
            timer(5000)
              .pipe(
                takeUntil(
                  merge(
                    this.destroyed$,
                    this.activeZoneConnectionChanged$,
                  )
                )
              )
              .subscribe(
                () => {
                  this.zoneConnectionService.loadAccessToken(this._activeZoneConnection);
                }
              )
          }

        }
      )
    }
  }
  private get _activeZoneConnection():ZoneConnection{
    return this._activeZoneConnectionSubject.value;
  }


  //helper function to add a zoneConnection in front, and remove any doubles (same zoneId)
  private addZoneConnectionToFront(zoneConnectionToAdd: ZoneConnection){
    let removedDoubleZoneConnections = this.zoneConnections;
    const zoneConnection = this.findConnectionForZoneId(zoneConnectionToAdd.zoneId, removedDoubleZoneConnections);
    let firstFoundZoneConnection =  zoneConnection;

    //keep removing all found instances (should only be 1)
    while (firstFoundZoneConnection != null){
      removedDoubleZoneConnections = removedDoubleZoneConnections.filter(zoneConnectionInArray => {return zoneConnectionInArray != firstFoundZoneConnection});
      firstFoundZoneConnection = this.findConnectionForZoneId(zoneConnectionToAdd.zoneId, removedDoubleZoneConnections);
    }

    this._zoneConnections = [zoneConnectionToAdd].concat(removedDoubleZoneConnections);
  }

  /**
   * The last active player zoneConnection
   * This can be used to show a logged out status in the zone connections view
   */
   private _lastActivePlayerZoneConnectionSubject = new BehaviorSubject<ZoneConnection>(null);
   lastActivePlayerZoneConnection$ = this._lastActivePlayerZoneConnectionSubject.asObservable();
   private set _lastActivePlayerZoneConnection(value: ZoneConnection){
     this._lastActivePlayerZoneConnectionSubject.next(value);
   }
   private get _lastActivePlayerZoneConnection():ZoneConnection{
     return this._lastActivePlayerZoneConnectionSubject.value;
   }
   public get lastActivePlayerZoneConnection():ZoneConnection{
     return this._lastActivePlayerZoneConnection;
   }

  /**
   * The last active zoneConnection
   * This can be used to
   *  - focus our login view
   *  - show error messages about removed connections
   */
  private _lastActiveZoneConnectionSubject = new BehaviorSubject<ZoneConnection>(null);
  lastActiveZoneConnection$ = this._lastActiveZoneConnectionSubject.asObservable();
  private set _lastActiveZoneConnection(value: ZoneConnection){
    this._lastActiveZoneConnectionSubject.next(value);
    this.saveLastActiveZoneConnection();
  }
  private get _lastActiveZoneConnection():ZoneConnection{
    return this._lastActiveZoneConnectionSubject.value;
  }
  public get lastActiveZoneConnection():ZoneConnection{
    return this._lastActiveZoneConnection;
  }


  /**
   * Flag that indicates if we are creating a zoneConnection
   */
  private _creatingZoneConnectionSubject = new BehaviorSubject<boolean>(false);
  creatingZoneConnection$ = this._creatingZoneConnectionSubject.asObservable();
  private set _creatingZoneConnection(value: boolean){
    this._creatingZoneConnectionSubject.next(value);
  }
  private get _creatingZoneConnection():boolean{
    return this._creatingZoneConnectionSubject.value;
  }
  public get creatingZoneConnection():boolean{
    return this._creatingZoneConnection;
  }

  /**
   * The error that occured while creating a zone connection
   * This will be null when no error occured (or we are creating a zoneConnection..)
   */
  private _creatingZoneConnectionErrorSubject = new BehaviorSubject<string>(null);
  creatingZoneConnectionError$ = this._creatingZoneConnectionErrorSubject.asObservable();
  private set _creatingZoneConnectionError(value: string){
    this._creatingZoneConnectionErrorSubject.next(value);
  }
  private get _creatingZoneConnectionError():string{
    return this._creatingZoneConnectionErrorSubject.value;
  }
  public get creatingZoneConnectionError():string{
    return this._creatingZoneConnectionError;
  }




  /**
   * Local storage of data
   */

  private zoneConnectionReviver = new ZoneConnectionReviver();
  private loadZoneConnections(){

    //load json string and transfor into zoneConnections
    const zoneConnectionsAsJson = this.localStorageService.zoneConnections;

    this.loggerService.info(this.LOGGER_CLASSNAME, 'loadZoneConnections', 'going to parse: ' + zoneConnectionsAsJson);
    if (zoneConnectionsAsJson != null){

      const revivedZoneConnections = this.zoneConnectionReviver.initFromJson(zoneConnectionsAsJson, null);
      const realZoneConnections = revivedZoneConnections as Array<ZoneConnection>;

      if (realZoneConnections){
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadZoneConnections', 'parsed ' + realZoneConnections.length + ' zoneConnections');
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, 'loadZoneConnections', 'parsed zoneConnections to null: ' + zoneConnectionsAsJson);
      }


      this._zoneConnections = realZoneConnections;

      /*
      var zoneConnections = JSON.parse(zoneConnectionsAsJson, function(key, value) {
        console.log(key);
        return key === '' && value.hasOwnProperty('__type')
          ? Types[value.__type].revive(value)
          : this[key];

      });
      */
    }else{
      this._zoneConnections = [];
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadZoneConnections', 'nothing to parse');
    }

    this.loadLastActiveZoneConnection();

    //BUGFIX after reviving objects -> convert to real dates
    this._zoneConnections.forEach((zoneConnection) => {
      if (zoneConnection.validCheckedDate != null){
        zoneConnection.validCheckedDate = new Date(zoneConnection.validCheckedDate);
      }
    });

    //we need a short delay to avoid cyclic dependency while creating instances (this instance is used in the Interceptors, so it can not fire requests right away)
    timer(50)
      .pipe(
        takeUntil(
          this.destroyed$
        )
      )
      .subscribe(
        () => {
          //re-evaluate all zoneConnections that are not check for the last period
          this._zoneConnections.forEach((zoneConnection) => {
            if (zoneConnection != this._activeZoneConnection && zoneConnection.needsValidityCheck()){
              this.loggerService.info(this.LOGGER_CLASSNAME, "loadZoneConnections", "Going to check the validity of connection for zone " + zoneConnection.zoneId);
              this.zoneConnectionService.loadAccessToken(zoneConnection);
            }
          });
        }
      );
  }

  private saveZoneConnections(){
    const zoneConnectionsAsJson = JSON.stringify(this.zoneConnections, ZoneConnection.persistentProperties);
    this.loggerService.info(this.LOGGER_CLASSNAME, 'saveZoneConnections', 'going to save ' + this.zoneConnections.length + ' zoneConnections as: ' + zoneConnectionsAsJson);
    this.localStorageService.zoneConnections = zoneConnectionsAsJson;
  }

  public loadActiveZoneConnection(applicationMode: ApplicationMode){
    this.activateZoneConnection(this.findConnectionForZoneId(this.localStorageService.activeZoneConnectionZoneId, this.zoneConnections), applicationMode);
  }

  private saveActiveZoneConnection(){
    if (this._activeZoneConnection && this.applicationMode == ApplicationMode.playerMode){
      this.localStorageService.activeZoneConnectionZoneId = this._activeZoneConnection.zoneId;
    }else{
      if (this.lastClearAsActive){
        this.lastClearAsActive = false;
        this.localStorageService.activeZoneConnectionZoneId = undefined;
        this.loggerService.info(this.LOGGER_CLASSNAME, "saveActiveZoneConnection", "cleared previous active connection (no auto activate on next startup)");
      }else{
        this.loggerService.info(this.LOGGER_CLASSNAME, "saveActiveZoneConnection", "clearing active connection is prevented (keep auto activate on next startup)");
      }

    }
  }

  private loadLastActiveZoneConnection(){
    const lastActiveZoneConnectionZoneId = this.localStorageService.lastActiveZoneConnectionZoneId;
    if (lastActiveZoneConnectionZoneId != null){
      //this.loggerService.debug(this.LOGGER_CLASSNAME, "loadLastActiveZoneConnection", "Going to startup zone connection with id " + lastActiveZoneConnectionZoneId);
      this._lastActiveZoneConnection = this.findConnectionForZoneId(lastActiveZoneConnectionZoneId, this.zoneConnections);
      if (this._lastActiveZoneConnection != null){
        this.loggerService.debug(this.LOGGER_CLASSNAME, "loadLastActiveZoneConnection", "Last active zoneConnection (zoneId: " + this._lastActiveZoneConnection.zoneId + ") loaded: " + this._lastActiveZoneConnection.name);
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "loadLastActiveZoneConnection", "No zoneConnection started while we should have started zoneId " + lastActiveZoneConnectionZoneId);
      }
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, "loadLastActiveZoneConnection", "Not going to startup any zone");
    }

  }

  private saveLastActiveZoneConnection(){
    if (this.lastActiveZoneConnection){
      this.localStorageService.lastActiveZoneConnectionZoneId = this.lastActiveZoneConnection.zoneId;
    }else{
      this.localStorageService.lastActiveZoneConnectionZoneId = undefined;
    }
  }



  /**
   * Connect a zone from a zoneCode
   */
  public connectZoneForZoneCode(zoneCode: string, autoStart: boolean, forcedApplicationMode: ApplicationMode){
    this._creatingZoneConnection = true;
    this._creatingZoneConnectionError = null;
    this.zoneConnectionApiService.loadRefreshTokenForZoneCode(zoneCode)
    .pipe(
      takeUntil(
        this.destroyed$
      ),
      finalize(
        () => {
          this._creatingZoneConnection = false;
        }
      )
    )
    .subscribe(
      data => {
        if (data) {
          const zoneConnection = this.createZoneConnectionFromRefreshTokenData(data);
          if (zoneConnection != null){
            this.addZoneConnectionToFront(zoneConnection);

            this.zoneConnectionService.loadAccessToken(zoneConnection);
            this.zoneConnectionService.loadZoneInfo(zoneConnection);
            this.emitZoneConnectionAdded(zoneConnection);

            if (autoStart){
              this.activateZoneConnection(zoneConnection, forcedApplicationMode);
            }

          }else{
            this.loggerService.error(this.LOGGER_CLASSNAME, "connectZoneForZoneCode response", "could not create zoneConnection from data, ignoring connection attempt for zoneCode " + zoneCode);
            this._creatingZoneConnectionError = "connectZone.error";
          }
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, "connectZoneForZoneCode response", "no data received, ignoring connection attempt for zoneCode " + zoneCode);
          this._creatingZoneConnectionError = "connectZone.error";
        }
      },
      (error: unknown) => {
        if (error instanceof HttpErrorResponse){
          if (error.status === 404){
            //not found  -> invalid code
            this._creatingZoneConnectionError = "connectZone.code.invalid";
          }else{
            this._creatingZoneConnectionError = "connectZone.error";
          }
          this.loggerService.error(this.LOGGER_CLASSNAME, "connectZoneForCredentials error response", "error: " + error);
        }else{
          this._creatingZoneConnectionError = "connectZone.error";
          this.loggerService.error(this.LOGGER_CLASSNAME, "connectZoneForCredentials error response", "received a non-HttpErrorResponse: " + error);
        }

        this._creatingZoneConnection = false;

      }
    );
  }

  private createZoneConnectionFromRefreshTokenData(data: DTO_RefreshToken): ZoneConnection{
    if (data.zoneId != null && data.refreshToken != null){
      const zoneConnection = new ZoneConnection();
      zoneConnection.zoneId = data.zoneId;
      zoneConnection.refreshToken = data.refreshToken;
      zoneConnection.valid = true;
      return zoneConnection;
    }
    return null;
  }

  public activateZoneConnection(zoneConnection: ZoneConnection, applicationMode: ApplicationMode){
    this.cleanUpAutoStart();
    this._activeZoneConnection = zoneConnection;
    this._applicationMode = applicationMode;
    this.triggerAutoStart();
    this.saveActiveZoneConnection();

    if (zoneConnection != null){

      zoneConnection.errorMessage = null;

      this.zoneConnectionService.loadAccessToken(zoneConnection);

      this.zoneConnectionService.loadZoneInfo(zoneConnection);

      this.loggerService.adjustToLoggedInZone(zoneConnection.zoneId);

      this._lastActiveZoneConnection = zoneConnection;
    }
  }

  //indicator if a deactivation needs to be cleared (eg, do not auto log on next time)
  private lastClearAsActive = false;
  /*
  public deactivateCurrentZoneConnection(reason: string, coloredReasonPart?: string, showSubscriptionsLink: boolean = false, clearAsActive = true){
    if (this._activeZoneConnection != null){
      this._activeZoneConnection.errorMessage = reason;
      this._activeZoneConnection.errorMessageColorPart = coloredReasonPart;
      this._activeZoneConnection.showSubscriptionLink = showSubscriptionsLink;
      this.lastClearAsActive = clearAsActive;
      this.activateZoneConnection(null, null);
    }
  }
  */

  public deactivateCurrentZoneConnection(errorMessage?: string, showSubscriptionsLink: boolean = false, clearAsActive = true){
    if (this._activeZoneConnection != null){
      this._activeZoneConnection.errorMessage = errorMessage;
      this._activeZoneConnection.showSubscriptionLink = showSubscriptionsLink;

      this.lastClearAsActive = clearAsActive;
      this.activateZoneConnection(null, null);
    }
  }

  /**
   *
   * Find a zoneConnection based on external zoneId.
   * Used at startup to avoid linking an already linked zone
   */
  public findConnectionForExternalZoneId(externalZoneId: string, appFamilyId: number): ZoneConnection{
    let zoneConnectionTemp = null;
    this.zoneConnections.forEach(zoneConnection => {
      if (zoneConnection.externalZoneId == externalZoneId && zoneConnection.appFamId == appFamilyId){
        zoneConnectionTemp = zoneConnection;
      }
    });
    return zoneConnectionTemp;
  }


  /**
   * Helper method to find a zoneConnection on id
   */
  private findConnectionForZoneId(zoneId: number, zoneConnections: readonly ZoneConnection[]): ZoneConnection{
    let zoneConnectionForZoneId = null;
    if (zoneId != null){

      zoneConnections.forEach(zoneConnection => {
        if (zoneConnection.zoneId == zoneId){
          zoneConnectionForZoneId = zoneConnection;
        }
      });
    }
    return zoneConnectionForZoneId;
  }

  public invalidateAccessToken(accessToken: string):void{
    if (this._activeZoneConnection){
      if (this._activeZoneConnection.accessToken){
        if (this._activeZoneConnection.accessToken == accessToken){
          this._activeZoneConnection.accessToken = null;
          this.zoneConnectionService.loadAccessToken(this._activeZoneConnection);
        }else{
          this.loggerService.warn(this.LOGGER_CLASSNAME, "invalidateAccessToken", "access token is not the current one: " + accessToken);
        }
      }else{
        this.loggerService.warn(this.LOGGER_CLASSNAME, "invalidateAccessToken", "Trying to invalidate an accessToken, but we have no current accessToken. The zoneConnection is " + (this.activeZoneConnection.loadingAccessToken?"":"NOT ") + "loading an accessToken");
      }
    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, "invalidateAccessToken", "Trying to invalidate an accessToken, but we don't have an active zoneConnection");
    }

  }


  /**
     * applicationMode (player mode / remote mode / not yet deciced (autostart and detecting other applications))
     */
   private get _applicationMode(): ApplicationMode {
    return this._applicationModeSubject.value;
  }
  private set _applicationMode(value: ApplicationMode) {
    if (this._applicationMode !== value) {
        if (value !== null){
            this.cleanUpForcePlayerModeTimeout();
        }
        this._applicationModeSubject.next(value);
    }
  }
  get applicationMode(): ApplicationMode {
    return this._applicationMode;
  }
  private _applicationModeSubject: BehaviorSubject<ApplicationMode> = new BehaviorSubject<ApplicationMode>(null);
  public applicationMode$: Observable<ApplicationMode> = this._applicationModeSubject.asObservable();

  /**
  * seconds before we force to start player mode (we need this if we can't connect to our remoting service)
  */
   private get _secondsBeforeAutoStartPlayerMode(): number {
    return this._secondsBeforeAutoStartPlayerModeSubject.value;
  }
  private set _secondsBeforeAutoStartPlayerMode(value: number) {
    if (this._secondsBeforeAutoStartPlayerMode !== value) {
        this._secondsBeforeAutoStartPlayerModeSubject.next(value);
    }
  }
  get secondsBeforeAutoStartPlayerMode(): number {
    return this._secondsBeforeAutoStartPlayerMode;
  }
  private _secondsBeforeAutoStartPlayerModeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.secondsBeforeAutoStart);
  public secondsBeforeAutoStartPlayerMode$: Observable<number> = this._secondsBeforeAutoStartPlayerModeSubject.asObservable();

  private forcePlayerModeTimeout: NodeJS.Timeout;
  private triggerAutoStart(){
    const currentZoneConnection = this._activeZoneConnection;
    if (this._activeZoneConnection){
      if (environment.allowMultipleLogin){
        this.checkExternalApplicationsBeforeAutoStart();
        if (this.applicationMode == null){
          //if not started by now -> wait until externalApplicationInfo is loaded
          this.forcePlayerModeTimeout = setInterval(this.forcePlayerModeTimerTick, 1000);
          this._activeZoneConnection.externalApplicationsInfo.externalApplicationsInfo$
          .pipe(
            takeUntil(
              merge(
                this.destroyed$,
                this._activeZoneConnection$.pipe(filter(zoneConnection => zoneConnection != currentZoneConnection)),
                this.applicationMode$.pipe(filter(applicationMode => applicationMode != null))
              )
            )
          )
          .subscribe(
            ()=>{
              this.checkExternalApplicationsBeforeAutoStart();
            }
          )
        }
      }else{
        if (this.applicationMode == null){
          this._applicationMode = ApplicationMode.playerMode;
        }
      }
    }
  }

  private checkExternalApplicationsBeforeAutoStart(){
    if (this._activeZoneConnection && this.applicationMode == null){
      if (this._activeZoneConnection.externalApplicationsInfo.externalApplicationsInfo != null){
        if (this._activeZoneConnection.externalApplicationsInfo.externalApplicationsInfo.filter(externalAppInfo => externalAppInfo.connectionType == ConnectionType.player).length == 0){
          this.ngZone.run(
            () => {
              this._applicationMode = ApplicationMode.playerMode;
            }
          )

        }else{
          //other player connected -> deactivate
          this.deactivateCurrentZoneConnection(null,false,false);
        }

      }
    }
  }

  private cleanUpAutoStart(){
    this.cleanUpForcePlayerModeTimeout();
    this._secondsBeforeAutoStartPlayerMode = this.secondsBeforeAutoStart;
  }


  private cleanUpForcePlayerModeTimeout(){
    if (this.forcePlayerModeTimeout){
        clearInterval(this.forcePlayerModeTimeout);
        this.forcePlayerModeTimeout = null;
    }
  }

  private forcePlayerModeTimerTick = () => {
    this._secondsBeforeAutoStartPlayerMode = this._secondsBeforeAutoStartPlayerMode - 1;
    if (this._secondsBeforeAutoStartPlayerMode <= 0){
        this.cleanUpForcePlayerModeTimeout();
        if (this._applicationMode === null){
            this._applicationMode = ApplicationMode.playerMode;
        }
    }
  }

  public startPlayerNow(){
    if (this.applicationMode == null){
      this._applicationMode = ApplicationMode.playerMode;
      this.cleanUpForcePlayerModeTimeout();
    }
  }



  /**
   * active zoneConnection to the outside world -> needs both an activeZoneConnetion and an application mode
   */
   public activeZoneConnection$ = combineLatest([this._activeZoneConnection$, this.applicationMode$])
   .pipe(
     map(
      ([zoneConnection, applicationMode]) => {
        if (applicationMode != null){
          return zoneConnection;
        }
        return null;
      }
     )
    );
   //this._activeZoneConnection$.pipe()

   public get activeZoneConnection():ZoneConnection{
     return (this.applicationMode != null ? this._activeZoneConnectionSubject.value : null);
   }

   public connectingZoneConnection$ = combineLatest([this._activeZoneConnection$, this.applicationMode$])
     .pipe(
       map(
        ([zoneConnection, applicationMode]) => {
          if (applicationMode == null){
            return zoneConnection;
          }
          return null;
        }
       )
      );

      public get connectingZoneConnection():ZoneConnection{
        return (this.applicationMode == null ? this._activeZoneConnectionSubject.value : null);
      }

    /**
     * Some wrapper observables
     */
     private dummyApplicationInfoSubject = new BehaviorSubject<ExternalApplicationInfo[]>(null);

     public get currentPlayerApplicationInfo$(){
       return this.currentPlayerApplicationInfos$
                .pipe(
                  map(
                    (zoneConnections) => {
                      if (zoneConnections != null && zoneConnections.length > 0){
                        return zoneConnections[0];
                      }
                      return null;
                    }
                  )
                )
     }

     //the current players that are remotely active (normally only 1)
     private get currentPlayerApplicationInfos$(){
       return this.activeZoneConnection$
         .pipe(
           switchMap(
             (zoneConnection) => {
               return zoneConnection != null ?
                 zoneConnection.externalApplicationsInfo.externalApplicationsInfo$.pipe(
                   map(
                     (appInfos) => {
                       if (appInfos != null){
                         return appInfos.filter(appInfo => appInfo.connectionType == ConnectionType.player)
                       }
                       return null;
                     }
                   )

                 )
                 : this.dummyApplicationInfoSubject.asObservable();
             }
           )
         )
     }

     public get currentRemotePlayerApplicationInfo():ExternalApplicationInfo{
       if (this.activeZoneConnection && this.activeZoneConnection.externalApplicationsInfo.externalApplicationsInfo != null){
         return this.activeZoneConnection.externalApplicationsInfo.externalApplicationsInfo.find(applicationInfo => applicationInfo.connectionType == ConnectionType.player);
       }
       return null;
     }

     //the last player that was remotely active
     private lastPlayerApplicationInfoSubject = new BehaviorSubject<ExternalApplicationInfo>(null);
     public lastPlayerApplicationInfo$ = this.lastPlayerApplicationInfoSubject.asObservable();

     //the current remotes that are remotely active
     public get currentRemoteApplicationInfos$(){
      return this.activeZoneConnection$
        .pipe(
          switchMap(
            (zoneConnection) => {
              return zoneConnection != null ?
                zoneConnection.externalApplicationsInfo.externalApplicationsInfo$.pipe(
                  map(
                    (appInfos) => {
                      if (appInfos != null){
                        return appInfos.filter(appInfo => appInfo.connectionType == ConnectionType.remote)
                      }
                      return null;
                    }
                  )

                )
                : this.dummyApplicationInfoSubject.asObservable();
            }
          )
        )
    }



}
