import { Injectable } from '@angular/core';
import { UAParser } from '@applandstream/ua-parser-js';
import { LoggerService } from '@service/loggers/logger.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';


export enum DesktopDeviceType{
  MAC = "Mac",
  WINDOWS = "Windows",
  LINUX = "Linux",
  OTHER = "other"
}


@Injectable({
  providedIn: 'root'
})
export class DesktopAppInstallerService {

  private LOGGER_CLASSNAME = 'DesktopAppInstallerService';

  public readonly desktopDeviceType: DesktopDeviceType;
  private arm64 = false;

  constructor(
    private loggerService: LoggerService,
    private deviceDetectorService: DeviceDetectorService
  ) {

    var parser = new UAParser();
      var cpu = parser.getCPU().withClientHints()
      if (cpu instanceof Promise) {
        cpu.then((result) => {
          let arch = result.architecture;
          if (arch != null){
            if (arch.toLowerCase().includes("arm64")){
              this.arm64 = true;
            }
          }
          console.log('Resolved arch:', arch);
        });

      }

      this.desktopDeviceType = this.deviceDetectorOSStringToDesktopDeviceType(deviceDetectorService.os);
  }

  private deviceDetectorOSStringToDesktopDeviceType(deviceOS: string){
    switch (deviceOS.toLowerCase()) {
      case 'mac':
        return DesktopDeviceType.MAC;
      case 'windows':
        return DesktopDeviceType.WINDOWS;
      case 'linux':
        return DesktopDeviceType.LINUX;
      default:
        return DesktopDeviceType.OTHER;
    }
  }

  public downloadDesktopApp(){
    if (this.desktopDeviceType == DesktopDeviceType.MAC){
      if (this.arm64){
        this.openSameWindow(environment.desktopAppMacarm64Location);
      }else{
        this.openSameWindow(environment.desktopAppMacx64Location);
      }
    }else if (this.desktopDeviceType == DesktopDeviceType.WINDOWS){
      this.openSameWindow(environment.desktopAppWindowsLocation);
    }else if (this.desktopDeviceType == DesktopDeviceType.LINUX){
      this.openSameWindow(environment.desktopAppLinuxLocation);
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "downloadDesktopApp", "Unsupported desktop device type: " + this.desktopDeviceType + ". deviceDetectorService.os: " + this.deviceDetectorService.os);
      this.openSameWindow(environment.desktopAppGeneralLocation);
    }
  }

  private openSameWindow(url: string){
    window.open(url, "_self");
  }
}
